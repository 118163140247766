import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@mui/material';
import Nav from '../components/Nav';
import SwitchLanguage from '../components/SwitchLanguage';
import axios from 'axios';
import { keycloak } from '../context/AuthContextProvider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const LeadResidence = ({ prevStep, nextStep, handleChange, values }) => {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [personId, setPersonId] = React.useState();

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState('');
  const [openMessageText, setMessageText] = useState(
    'There is nothing for now'
  );

  const { t } = useTranslation();

  // MULTISELECT

  const [residenceName, setResidenceName] = React.useState([]);
  const [residenceTable, setResidenceTable] = React.useState([]);
  const [residencesName, setResidencesName] = React.useState([]);

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];
    let residenceTable = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
        residenceTable.push(item.one_key);
      }
    });

    setResidenceName(duplicateRemoved);
    setResidenceTable(residenceTable);
  };

  useEffect(() => {
    async function getResidences() {
      axios
        .get(
          (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
            'residences',
          {
            headers: {
              Authorization: 'Bearer ' + keycloak.token,
            },
          }
        )
        .then((response) => {
          setResidencesName(response.data);
        });
    }
    getResidences();
  }, []);

  const Continue = (e) => {
    e.preventDefault();
    console.log(residenceTable);
    if (values.resident_type === '' || residenceTable === []) {
      setOpen(true);
    } else {
      const params = {
        caller_lastname: values.name,
        caller_firstname: values.surname,
        caller_lang: values.language,
        caller_email: values.email,
        caller_phone: values.phone,
        caller_referrer: values.resident_referer,
        resident_firstname: values.resident_surname,
        resident_lastname: values.resident_name,
        resident_age: values.resident_age,
        resident_address: values.resident_address,
        resident_sex: values.resident_gender,
        resident_zipcode: values.resident_zipcode,
        resident_phone: values.resident_phone,
        resident_mail: values.resident_email,
        resident_budget: values.resident_budget,
        resident_lang: values.resident_language,
        questions_remarks: values.questions_remarks,
        resident_type: values.resident_type,
        residences: residenceTable.toString(),
      };

      for (const key of Object.keys(params)) {
        if (params[key] === null || params[key] === '' || params[key] === {}) {
          delete params[key];
        }
      }
      postData(e);
      async function postData(e) {
        e.preventDefault();
        await axios
          .post(
            (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
              'leads',
            params,
            {
              headers: {
                Authorization: 'Bearer ' + keycloak.token,
              },
            }
          )
          .then((response) => {
            if (response.data.exists === true) {
              handleOpen();
              setPersonId(response.data.id);
            } else {
              setOpen(false);
              nextStep();
            }
          })
          .catch(function (error) {
            setSeverity('error');
            setOpenMessage(true);
            setMessageText(
              error.response.data.errors[0]['param'] +
                ' : ' +
                error.response.data.errors[0]['msg']
            );
          });
      }
    }
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div className="wrapper">
      <header>
        <Nav />
      </header>

      <main className="lead">
        <div className="lead__header">
          <h1 className="lead__header__title">{t('lead-title')}</h1>
          <p className="lead__header__description">{t('lead-text')}</p>
        </div>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Collapse in={open}>
            <Alert className="lead__error" severity="error">
              {t('required-fields')}
            </Alert>
          </Collapse>
          <Collapse in={openMessage}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenMessage(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {openMessageText}
            </Alert>
          </Collapse>
        </Stack>
        <Modal
          className="tasks__modal"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="alert-modal">
            <h3>{t('already-exists-title')}</h3>
            <p className="alert-modal__p">{t('already-exists-text')}</p>
            <Link className="alert-modal__link" to={`/post/${personId}`}>
              <span className="link">{t('already-exists-link')}</span>
            </Link>
            <small className="alert-modal__small" onClick={handleClose}>
              {t('already-exists-close')}
            </small>
          </Box>
        </Modal>

        <h2 className="lead__step__title">{t('step-03')}</h2>

        <form className="form form--entry">
          <div className="form__row form__row--secondary">
            <div className="form__field">
              <p className="form__field__title">{t('budget')}</p>
              <Select
                label="Language"
                onChange={handleChange('resident_budget')}
                defaultValue={values.resident_budget}
                variant="filled"
                id="filled-basic"
              >
                <MenuItem value={'1500'}>1500&euro;</MenuItem>
                <MenuItem value={'1500-2000'}>1500&euro;-2000&euro;</MenuItem>
                <MenuItem value={'2000'}>2000&euro;</MenuItem>
                <MenuItem value={'2000-2500'}>2000&euro;-2500&euro;</MenuItem>
                <MenuItem value={'2500-3000'}>2500&euro;-3000&euro;</MenuItem>
                <MenuItem value={'3000-3500'}>3000&euro;-3500&euro;</MenuItem>
                <MenuItem value={'+3500'}>+3500&euro;</MenuItem>
              </Select>
            </div>
            <div className="form__field">
              <p className="form__field__title">{t('type')}*</p>
              <Select
                required
                label="Language"
                onChange={handleChange('resident_type')}
                defaultValue={values.resident_type}
                variant="filled"
                id="filled-basic"
              >
                <MenuItem value={'REST_HOME'}>{t('rest-home')}</MenuItem>
                <MenuItem value={'SERVICE_RESIDENCE'}>
                  {t('service-residence')}
                </MenuItem>
                <MenuItem value={'SHORT_STAY'}>{t('short-stay')}</MenuItem>
              </Select>
            </div>
            <div className="form__field">
              <p className="form__field__title">{t('send-to-residence')}*</p>
              <FormControl>
                <Select
                  multiple
                  value={residenceName}
                  onChange={handleChangeSelect}
                  renderValue={(selected) =>
                    selected.map((x) => x.name).join(', ')
                  }
                  variant="filled"
                >
                  {residencesName.map((variant) => (
                    <MenuItem key={variant.one_key} value={variant}>
                      <Checkbox
                        checked={
                          residenceName.findIndex(
                            (item) => item.one_key === variant.one_key
                          ) >= 0
                        }
                      />
                      {variant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="form__next form__next--entry form__next--lead">
              <Button
                className="form__previous"
                onClick={Previous}
                type="submit"
              >
                <span>{t('previous')}</span>
              </Button>
              <Button className="form__submit" onClick={Continue}>
                {t('add-the-lead')}
              </Button>
            </div>
          </div>
          <div className="form__row form__row--secondary form__row--width">
            <div className="form__field">
              <p className="form__field__title">{t('questions-notes')}</p>
              <TextField
                placeholder="Questions/Notes"
                onChange={handleChange('questions_remarks')}
                defaultValue={values.questions_remarks}
                variant="filled"
                id="filled-basic"
              />
            </div>
          </div>
        </form>
        <SwitchLanguage />
      </main>
    </div>
  );
};

export default LeadResidence;
