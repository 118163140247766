import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { keycloak } from '../context/AuthContextProvider';
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import Loading from './Loading';
import Locked from './Locked';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

export default function TaskGridData(props) {
  const [filtered, setfiltered] = useState(false);
  const [setArchived, setSetArchived] = useState(false);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setShowModal(false);
  const [handling, setHandling] = useState(false);
  const [handleModalOpen, setHandleModalOpen] = useState(false);

  const { t } = useTranslation();

  const sleep = (milliseconds = 500) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

  async function getData() {
    await axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          'registrations',
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setLoadingData(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function messageDataGrid() {
    if (loadingData === true) {
      return <Loading />;
    } else {
      return <p className="error__title">{t('listing-no-data')}</p>;
    }
  }

  useEffect(() => {
    setData(props.leads);
    if (props.inputValue.length == 0) {
      if (loadingData) {
        getData();
      }
    }
    if (props.open === false) {
      getData();
     }
  }, [props.leads]);

  const columns = [
    {
      field: 'locked',
      headerName: t('listing-th-actions'),
      type: 'number',
      width: 140,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        if (params.row.locked !== 1) {
          const rowID = params.row.id;
          let cellClass = 'listing__cell';
          if (params.row.targets > 1) {
            cellClass = 'listing__cell listing__cell--bcg';
          }
          return (
            <div
              className={`margin-left cellClass ${cellClass}`}
              onClick={(e) => {
                e.stopPropagation();
                handleRow(rowID);
                setSelectedRowData(params.row);
              }}
            >
              <button
                className={
                  params.row.archived_at !== null
                    ? 'handle incharge single__footer__element--disabled'
                    : 'handle incharge'
                }
              >
                <span className="incharge__text">{t('listing-tr-handle')}</span>
              </button>
            </div>
          );
        } else {
          return (
            <div
              className="margin-left cellClass"
              onClick={(e) => e.stopPropagation()}
            >
              <Locked />
            </div>
          );
        }
      },
    },

    {
      field: 'id',
      headerName: t('listing-th-id'),
      width: '100',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        if (params.row.locked !== 1) {
          const rowID = params.row.id;

          return (
            <div
              className={cellClass}
              onClick={(e) => {
                e.stopPropagation();
                handleRow(rowID);
                setSelectedRowData(params.row);
              }}
            >
              {params.row.id}
            </div>
          );
        } else {
          return <div className={cellClass}>{params.row.id}</div>;
        }
      },
      valueGetter: (params) => `${params.row.id}`,
    },
    {
      field: 'lead_lastname',
      headerName: t('listing-th-lead-lastname'),
      width: '150',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        if (params.row.locked !== 1) {
          const rowID = params.row.id;

          return (
            <div
              className={cellClass}
              onClick={(e) => {
                e.stopPropagation();
                handleRow(rowID);
                setSelectedRowData(params.row);
              }}
            >
              {params.row.lead_lastname}
            </div>
          );
        } else {
          return <div className={cellClass}>{params.row.lead_lastname}</div>;
        }
      },
      valueGetter: (params) => `${params.row.lead_lastname}`,
    },
    {
      field: 'lead_firstname',
      headerName: t('listing-th-lead-firstname'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        if (params.row.locked !== 1) {
          const rowID = params.row.id;

          return (
            <div
              className={cellClass}
              onClick={(e) => {
                e.stopPropagation();
                handleRow(rowID);
                setSelectedRowData(params.row);
              }}
            >
              {params.row.lead_firstname}
            </div>
          );
        } else {
          return <div className={cellClass}>{params.row.lead_firstname}</div>;
        }
      },
      valueGetter: (params) => `${params.row.lead_firstname}`,
    },
  ];

  // HANDLE LEAD

  async function handleEntry(rowID) {
    const residence = localStorage.getItem('selectedResidence');
    return axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${rowID}/handle`,
        {
          residence: residence || '',
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .catch(function (error) {
        if (error.response.status <= 404) {
          alert(error.response.data + '   ' + error.response.status);
        }
      });
  }

  const handleRow = (rowID) => {
    setShowModal(true);
    setSelectedRow(rowID);
  };

  const handleEntryWithDelay = async (event, rowID) => {
    event.preventDefault();
    setHandling(true);
    await sleep(2500);
    const data = await handleEntry(rowID);
    if (data) {
      props.setStep(2);
      props.onRowClick(selectedRowData);
    }
    setHandling(false);
    handleClose();
  };



  return (
    <>
      <div className="tasks__modal__grid" role="alert">
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={Array.from(data)}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            getRowId={(row) => row.id}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: messageDataGrid,
            }}
            onRowClick={(params) => {
              props.onRowClick(params.row);
            }}
          />
        </Box>
      </div>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="tasks__modal"
      >
        <Box className="tasks__modal__container tasks__modal__container--small tasks__modal__container--over">
          <div className="modal__content">
            <div className="modal__content__header">
              <h3 className="modal__content__title">
                {t('Do you want to handle this person?')}
              </h3>
              <p className="tasks__modal__content__subtitle">
                {t("A 'call contact' task will also be added")}
              </p>
            </div>
            <div className="modal__content__footer">
              <Button
                className={'form__submit form__submit--open '}
                onClick={(event) => {
                  handleEntryWithDelay(event, selectedRow);
                }}
              >
                <span className="incharge__text">
                  {handling
                    ? t('Handle process is running...')
                    : t('Oui, je le prends en charge')}
                </span>
              </Button>
              <Button
                className="alert-modal__close-btn"
                onClick={() => {
                  handleClose();
                }}
              >
                <span className="incharge__text">{t('I give up')}</span>
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
