import React, { Suspense } from 'react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { keycloak } from '../context/AuthContextProvider';
import SwitchLanguage from '../components/SwitchLanguage';
import Nav from '../components/Nav';
import { TextField, Button } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FooterSingle from '../components/FooterSingle';
import { useTranslation } from 'react-i18next';
import OpenButton from '../components/OpenButton';
import NotificationBox from '../components/NotificationBox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';

export default function Post() {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const { id } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const active = searchParams.get('active');

  const [name, setName] = useState({});
  const [surname, setSurname] = useState({});
  const [phone, setPhone] = useState({});
  const [email, setEmail] = useState({});
  const [language, setLanguage] = useState({});
  const [resident_name, setResidentName] = useState({});
  const [resident_surname, setResidentSurname] = useState({});
  const [resident_age, setResidentAge] = useState({});
  const [resident_gender, setResidentGender] = useState({});
  const [resident_language, setResidentLanguage] = useState({});
  const [resident_address, setResidetaddress] = useState({});
  const [resident_zipcode, setResidentZipcode] = useState({});
  const [resident_phone, setResidentPhone] = useState({});
  const [resident_email, setResidentEmail] = useState({});
  const [resident_budget, setResidentBudget] = useState({});
  const [questions_remarks, setQuestionRemarks] = useState({});
  const [resident_type, setResidentType] = useState({});
  const [referer, setReferer] = useState({});
  const [complaint, setComplaint] = useState({});
  const [question, setQuestion] = useState({});
  const [buttonOpen, setButtonOpen] = useState({});
  const [reason, setReason] = useState({});

  const [locked, setLocked] = useState({});

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState('');
  const [openMessageText, setMessageText] = useState('');
  const [openMessageFull, setMessageFull] = useState('');

  const [errorCode, setErrorCode] = useState();
  document.title = 'Leadtool v3 | Emeis Belgium';

  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const [openModalSend, setOpenModalSend] = useState(false);

  const handleCloseSend = () => {
    setOpenModalSend(false);
  };

  async function getData() {
    await axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations?registration-ids=${id}${
            active === '0' ? '&active=false' : ''
          }`,
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setData(response.data);

          setName(response.data[0].caller_lastname);
          setSurname(response.data[0].caller_firstname);
          setLanguage(response.data[0].caller_lang);
          setEmail(response.data[0].caller_email);
          setPhone(response.data[0].resident_phone);
          setReferer(response.data[0].caller_referrer);
          setResidentAge(response.data[0].resident_age);
          setResidetaddress(response.data[0].resident_address);
          setResidentType(response.data[0].resident_type);
          setResidentBudget(response.data[0].resident_budget);
          setQuestionRemarks(response.data[0].questions_remarks);
          setResidentName(response.data[0].lead_lastname);
          setResidentLanguage(response.data[0].lead_language);
          setResidentEmail(response.data[0].lead_email);
          setResidentZipcode(response.data[0].lead_zipcode);
          setResidentPhone(response.data[0].lead_phone);
          setResidentSurname(response.data[0].lead_firstname);
          setResidentGender(response.data[0].lead_gender);
          setLocked(response.data[0].locked);
          setQuestion(response.data[0].question);
          setComplaint(response.data[0].complaint);
          setButtonOpen(response.data[0].registration_status);

          setLoadingData(false);
        }
      })
      .catch((error) => {
        setOpenMessage(false); // Add this line
        console.error(error);
        setOpenMessage(true);
        setSeverity('error');
        setMessageText('There was an error retrieving the data');
      });
  }
  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, []);

  const Continue = (e) => {
    e.preventDefault();
    putData(e);
    async function putData(e) {
      e.preventDefault();
      const params = {
        caller_firstname: surname,
        caller_lastname: name,
        caller_lang: language,
        caller_email: email,
        caller_phone: phone,
        caller_referrer: referer,
        resident_firstname: resident_surname,
        resident_lastname: resident_name,
        resident_age: resident_age,
        resident_sex: resident_gender,
        resident_lang: resident_language,
        resident_address: resident_address,
        resident_zipcode: resident_zipcode,
        resident_phone: resident_phone,
        resident_mail: resident_email,
        resident_type: resident_type,
        resident_budget: resident_budget,
        questions_remarks: questions_remarks,
        question: question,
        complaint: complaint,
      };

      for (const key of Object.keys(params)) {
        if (params[key] === null || params[key] === '' || params[key] === {}) {
          delete params[key];
        }
      }

      await axios
        .put(
          (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
            `registrations/${data[0].id}`,
          params,
          {
            headers: {
              Authorization: 'Bearer ' + keycloak.token,
            },
          }
        )
        .then((reponse) => {
          setOpenMessage(false); // Add this line
          setSeverity('success');
          setOpenMessage(true);
          setMessageText('This registration has been updated');
          setTimeout(() => {
            getData();
          }, 1000);

          // Close the alert after 5 seconds
          setTimeout(() => {
            setOpenMessage(false);
          }, 5000);
        })
        .catch(function (error) {
          setOpenMessage(false); // Add this line
          setSeverity('error');
          setOpenMessage(true);
          setMessageText(error.response.data.error_description);
          setErrorCode(error.response.data.error_code);

          if (
            error.response.data.errors?.length > 0 &&
            error.response.data.errors[0]?.msg
          ) {
            setMessageFull(error.response.data.errors[0].msg);
          } else {
            setMessageFull('');
          }
        });
    }
  };

  function reorient(id) {
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}/reorient`,
        {
          residences: residenceTable.toString(),
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setOpenMessage(false); // Add this line
        setSeverity('success');
        setOpenMessage(true);
        setMessageText('This registration has been updated');
        setTimeout(() => {
          setOpenMessage(false);
        }, 4000);
        setOpenModalSend(false);
        setTimeout(() => {
          getData();
        }, 1000);

        setTimeout(() => {
          window.location.href = '/listing';
        }, 1000);
      })
      .catch(function (error) {
        setOpenMessage(false); // Add this line
        setSeverity('error');
        setOpenMessage(true);
        setMessageText(error.response.data.error_description);
        setErrorCode(error.response.data.error_code);

        if (
          error.response.data.errors?.length > 0 &&
          error.response.data.errors[0]?.msg
        ) {
          setMessageFull(error.response.data.errors[0].msg);
        } else {
          setMessageFull('');
        }
      });
  }

  function close(id) {
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}/close`,
        {
          closing_reason: reason.toString(),
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setOpenMessage(false); // Add this line
        setSeverity('success');
        setOpenMessage(true);
        setMessageText('This registration has been updated');
        setTimeout(() => {
          window.location.href = '?active=0';
        }, 1000);
        setTimeout(() => {
          getData();
        }, 1000);
        handleClose();
      })
      .catch(function (error) {
        setOpenMessage(false); // Add this line
        setSeverity('error');
        setOpenMessage(true);
        setMessageText(error.response.data.error_description);
        setErrorCode(error.response.data.error_code);

        if (
          error.response.data.errors?.length > 0 &&
          error.response.data.errors[0]?.msg
        ) {
          setMessageFull(error.response.data.errors[0].msg);
        } else {
          setMessageFull('');
        }
      });
  }

  function open(id) {
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}/open`,
        JSON.stringify(),
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setOpenMessage(false); // Add this line
        setSeverity('success');
        setOpenMessage(true);
        setMessageText('This registration has been updated');

        setTimeout(() => {
          const url = new URL(window.location.href);
          url.searchParams.delete('active');
          window.location.href = url.toString();
        }, 1000);

        setTimeout(() => {
          getData();
        }, 1000);
      })
      .catch(function (error) {
        setOpenMessage(false); // Add this line
        setSeverity('error');
        setOpenMessage(true);
        setMessageText(error.response.data.error_description);
        setErrorCode(error.response.data.error_code);

        if (
          error.response.data.errors?.length > 0 &&
          error.response.data.errors[0]?.msg
        ) {
          setMessageFull(error.response.data.errors[0].msg);
        } else {
          setMessageFull('');
        }
      });
  }

  function archive(id) {
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}/archive`,
        JSON.stringify(),
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setOpenMessage(false); // Add this line
        setSeverity('success');
        setOpenMessage(true);
        setMessageText('This registration has been updated');
        setTimeout(() => {
          window.location.href = '?active=0';
        }, 1000);
      })
      .catch(function (error) {
        setOpenMessage(false); // Add this line
        setSeverity('error');
        setOpenMessage(true);
        setMessageText(error.response.data.error_description);
        setErrorCode(error.response.data.error_code);

        if (
          error.response.data.errors?.length > 0 &&
          error.response.data.errors[0]?.msg
        ) {
          setMessageFull(error.response.data.errors[0].msg);
        } else {
          setMessageFull('');
        }
      });
  }

  async function unarchive(id) {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}/unarchive`,
        JSON.stringify(),
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      );

      setOpenMessage(false); // Add this line
      setSeverity('success');
      setOpenMessage(true);
      setMessageText('This registration has been updated');

      setTimeout(() => {
        const url = new URL(window.location.href);
        console.log('Here' + data[0].registration_status);

        if (data[0].registration_status !== 'CLOSED') {
          url.searchParams.delete('active');
          window.location.href = url.toString();
        } else {
          window.location.href = url.toString();
        }
      }, 1000);
    } catch (error) {
      setOpenMessage(false); // Add this line
      setSeverity('error');
      setOpenMessage(true);
      setMessageText(error.response.data.error_description);
      setErrorCode(error.response.data.error_code);

      if (
        error.response.data.errors?.length > 0 &&
        error.response.data.errors[0]?.msg
      ) {
        setMessageFull(error.response.data.errors[0].msg);
      } else {
        setMessageFull('');
      }
    }
  }

  // MULTISELECT

  const [residenceName, setResidenceName] = React.useState([]);
  const [residenceTable, setResidenceTable] = React.useState([]);
  const [residencesName, setResidencesName] = React.useState([]);

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];
    let residenceTable = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
        residenceTable.push(item.one_key);
      }
    });

    setResidenceName(duplicateRemoved);
    setResidenceTable(residenceTable);
  };

  useEffect(() => {
    async function getResidences() {
      axios
        .get(
          (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
            'residences',
          {
            headers: {
              Authorization: 'Bearer ' + keycloak.token,
            },
          }
        )
        .then((response) => {
          setResidencesName(response.data);
        });
    }
    getResidences();
  }, []);

  let list = '';

  async function handleEntry(rowID) {
    const residence = localStorage.getItem('selectedResidence');
    await axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${rowID}/handle`,
        {
          residence: residence || '',
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        getData();
      })
      .catch(function (error) {
        if (error.response.status <= 404) {
          alert(error.response.data + '   ' + error.response.status);
        }
      });
  }

  //-------------------------------------------------------------------------------------------------------- LEAD TYPE HERE
  if (
    data[0] !== undefined &&
    data[0].registration_type === 'RESERVATION_URGENT'
  ) {
    let reasonField = '';

    if (data[0].registration_status_reason !== null) {
      reasonField = (
        <div className="form__field form__row--secondary">
          <p className="form__field__title">{t('closing-reason')}</p>
          <TextField
            defaultValue={t(data[0].registration_status_reason)}
            variant="filled"
            id="filled-basic"
          />
        </div>
      );
    }
    return (
      <div>
        <Suspense fallback={<div>loading...</div>}>
          <div className="wrapper">
            <header>
              <Nav />
            </header>
            <main className="single">
              {openMessage && (
                <NotificationBox
                  num={errorCode}
                  desc={openMessageText}
                  severity={severity}
                  full={openMessageFull}
                />
              )}

              <div className="single__modal">
                <Dialog open={openModal} onClose={handleClose}>
                  <DialogTitle>{t('select-reason')}</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth variant="filled">
                      <Select
                        onChange={(event) => setReason(event.target.value)}
                        variant="filled"
                        id="filled-basic"
                      >
                        <MenuItem value={'DECEASED'}>{t('DECEASED')}</MenuItem>
                        <MenuItem value={'STAY_HOME'}>
                          {t('STAY_HOME')}
                        </MenuItem>
                        <MenuItem value={'ENTERED_COMPETITION'}>
                          {t('ENTERED_COMPETITION')}
                        </MenuItem>
                        <MenuItem value={'ENTERED_RESIDENCE'}>
                          {t('ENTERED_RESIDENCE')}
                        </MenuItem>
                        <MenuItem value={'ENTERED_HEALTH_GROUP_SUBSIDIARY'}>
                          {t('ENTERED_HEALTH_GROUP_SUBSIDIARY')}
                        </MenuItem>
                        <MenuItem value={'BUDGET'}>{t('BUDGET')}</MenuItem>
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>{t('cancel-task')}</Button>
                    <Button onClick={() => close(id)} color="primary">
                      {t('add-the-question')}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openModalSend} onClose={handleCloseSend}>
                  <DialogTitle>{t('select-residences')}</DialogTitle>
                  <DialogContent>
                    <FormControl>
                      <Select
                        multiple
                        value={residenceName}
                        onChange={handleChangeSelect}
                        renderValue={(selected) =>
                          selected.map((x) => x.name).join(', ')
                        }
                        variant="filled"
                      >
                        {residencesName.map((variant) => (
                          <MenuItem key={variant.one_key} value={variant}>
                            <Checkbox
                              checked={
                                residenceName.findIndex(
                                  (item) => item.one_key === variant.one_key
                                ) >= 0
                              }
                            />
                            {variant.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseSend}>
                      {t('cancel-task')}
                    </Button>
                    <Button onClick={() => reorient(id)} color="primary">
                      {t('add-the-question')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div className="single__footer__container">
                {data[0].locked !== 0 ? (
                  <>
                    <div className="single__footer__element">
                      <Button
                        className="form__submit form__submit--send"
                        onClick={() => setOpenModalSend(true)}
                      >
                        {t('toolbar-send')}
                      </Button>
                    </div>

                    <div
                      className={
                        data[0].registration_status === 'CLOSED'
                          ? 'single__footer__element single__footer__element--disabled'
                          : 'single__footer__element'
                      }
                    >
                      <Button
                        className="form__submit form__submit--close"
                        onClick={() => setOpenModal(true)}
                      >
                        {t('toolbar-close')}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="margin-left cellClass">
                    <button
                      onClick={() => handleEntry(id)}
                      className={
                        data[0].archived_at !== null
                          ? 'handle incharge incharge--single single__footer__element--disabled'
                          : 'handle incharge incharge--single'
                      }
                    >
                      <span className="incharge__text">
                        {t('listing-tr-handle')}
                      </span>
                    </button>
                  </div>
                )}

                <div
                  className={
                    data[0].registration_status === 'OPEN' ||
                    data[0].archived_at !== null
                      ? 'single__footer__element single__footer__element--disabled'
                      : 'single__footer__element'
                  }
                >
                  <Button
                    className="form__submit form__submit--open"
                    onClick={() => open(id)}
                  >
                    {t('toolbar-open')}
                  </Button>
                </div>

                {data[0].archived_at === null ? (
                  <div className="single__footer__element">
                    <Button
                      className="form__submit form__submit--archive"
                      onClick={() => archive(id)}
                    >
                      {t('toolbar-archive')}
                    </Button>
                  </div>
                ) : (
                  <div className="single__footer__element">
                    <Button
                      className="form__submit form__submit--archive"
                      onClick={() => unarchive(id)}
                    >
                      {t('toolbar-unarchive')}
                    </Button>
                  </div>
                )}

                <div className="single__footer__element single__footer__element--last">
                  <div className="single__footer__btn single__footer__btn--update">
                    <Button className="form__submit" onClick={Continue}>
                      {t('update')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="single__header">
                <NavLink className="single__header__link" to="/listing" end>
                  {t('previous')}
                </NavLink>
                <h1 className="single__header__title">
                  {data[0].lead_lastname} {data[0].lead_firstname}
                </h1>
                <div className="single__state">
                  <OpenButton open={buttonOpen} />
                </div>
              </div>

              <form className="form">
                <h2 className="lead__step__title lead__step__title--margin">
                  {t('single-incoming-caller')}
                </h2>
                <div className="form__row">
                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-firstname')}
                    </p>
                    <TextField
                      required
                      placeholder={t('single-incoming-caller')}
                      onChange={(event) => setName(event.target.value)}
                      defaultValue={data[0].caller_lastname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-lastname')}</p>
                    <TextField
                      required
                      placeholder={t('single-lastname')}
                      onChange={(event) => setSurname(event.target.value)}
                      defaultValue={data[0].caller_firstname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-phone')}</p>
                    <TextField
                      required
                      placeholder={t('single-phone')}
                      onChange={(event) => setPhone(event.target.value)}
                      defaultValue={data[0].caller_phone}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                </div>

                <div className="form__row form__row--secondary">
                  <div className="form__field">
                    <p className="form__field__title">{t('single-email')}</p>
                    <TextField
                      placeholder={t('single-email')}
                      onChange={(event) => setEmail(event.target.value)}
                      defaultValue={data[0].caller_email}
                      autoComplete="email"
                      variant="filled"
                      id="filled-basic"
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-language')}</p>
                    <Select
                      required
                      onChange={(event) => setLanguage(event.target.value)}
                      label="Choose a language"
                      defaultValue={data[0].caller_language}
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'FR'}>{t('french')}</MenuItem>
                      <MenuItem className="menuitem--last" value={'NL'}>
                        {t('neederlands')}
                      </MenuItem>
                      {/* 'EN', 'DE', 'IT','TR', 'ES', 'AR' */}
                      <MenuItem value={'EN'}>{t('english')}</MenuItem>
                      <MenuItem value={'DE'}>{t('german')}</MenuItem>
                      <MenuItem value={'IT'}>{t('italian')}</MenuItem>
                      <MenuItem value={'TR'}>{t('turkish')}</MenuItem>
                      <MenuItem value={'ES'}>{t('spanish')}</MenuItem>
                      <MenuItem value={'AR'}>{t('arabic')}</MenuItem>
                    </Select>
                  </div>
                </div>
                <br />
              </form>

              <form className="form">
                <h2 className="lead__step__title lead__step__title--margin">
                  {t('single-resident-info')}
                </h2>
                <div className="form__row">
                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-firstname')}
                    </p>
                    <TextField
                      required
                      placeholder={t('single-firstname')}
                      onChange={(event) => setResidentName(event.target.value)}
                      defaultValue={data[0].lead_lastname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-lastname')}</p>
                    <TextField
                      required
                      placeholder={t('single-lastname')}
                      onChange={(event) =>
                        setResidentSurname(event.target.value)
                      }
                      defaultValue={data[0].lead_firstname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-age')}</p>
                    <TextField
                      required
                      placeholder={t('single-age')}
                      onChange={(event) => setResidentAge(event.target.value)}
                      defaultValue={data[0].lead_age}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                </div>

                <div className="form__row form__row--secondary">
                  <div className="form__field">
                    <p className="form__field__title">{t('single-language')}</p>
                    <Select
                      required
                      label={t('single-language')}
                      defaultValue={data[0].lead_language}
                      onChange={(event) =>
                        setResidentLanguage(event.target.value)
                      }
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'FR'}>{t('language-fr')}</MenuItem>
                      <MenuItem className="menuitem--last" value={'NL'}>
                        {t('language-nl')}
                      </MenuItem>
                      {/* 'EN', 'DE', 'IT','TR', 'ES', 'AR' */}
                      <MenuItem value={'EN'}>{t('english')}</MenuItem>
                      <MenuItem value={'DE'}>{t('german')}</MenuItem>
                      <MenuItem value={'IT'}>{t('italian')}</MenuItem>
                      <MenuItem value={'TR'}>{t('turkish')}</MenuItem>
                      <MenuItem value={'ES'}>{t('spanish')}</MenuItem>
                      <MenuItem value={'AR'}>{t('arabic')}</MenuItem>
                    </Select>
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-adress')}</p>
                    <TextField
                      placeholder={t('single-adress')}
                      defaultValue={data[0].lead_address}
                      onChange={(event) =>
                        setResidetaddress(event.target.value)
                      }
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-email')}</p>
                    <TextField
                      placeholder={t('single-email')}
                      onChange={(event) => setResidentEmail(event.target.value)}
                      defaultValue={data[0].lead_email}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                </div>

                <div className="form__row form__row--secondary">
                  <div className="form__field">
                    <p className="form__field__title">{t('single-phone')}</p>
                    <TextField
                      placeholder={t('single-phone')}
                      onChange={(event) => setResidentPhone(event.target.value)}
                      defaultValue={data[0].lead_phone}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                  {/* <div className="form__field">
                    <p className="form__field__title">{t('single-gender')}</p>
                    <TextField
                      placeholder={t('single-gender')}
                      onChange={(event) =>
                        setResidentGender(event.target.value)
                      }
                      defaultValue={data[0].lead_gender}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div> */}
                  <div className="form__field">
                    <p className="form__field__title">{t('gender')}</p>
                    <Select
                      label={t('gender')}
                      onChange={(event) =>
                        setResidentGender(event.target.value)
                      }
                      defaultValue={data[0].lead_gender}
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'M'}>{t('gender-m')}</MenuItem>
                      <MenuItem value={'F'}>{t('gender-f')}</MenuItem>
                      <MenuItem value={'OTHER'}>{t('gender-lgbt+')}</MenuItem>
                    </Select>
                  </div>
                  <div className="form__field">
                    <p className="form__field__title">{t('single-zipcode')}</p>
                    <TextField
                      placeholder={t('single-zipcode')}
                      onChange={(event) =>
                        setResidentZipcode(event.target.value)
                      }
                      defaultValue={data[0].lead_zipcode}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                </div>

                <div className="form__row form__row--secondary">
                  <div className="form__field">
                    <p className="form__field__title">{t('single-referer')}</p>
                    <Select
                      required
                      label={t('single-referer')}
                      onChange={(event) => setReferer(event.target.value)}
                      defaultValue={data[0].caller_referrer}
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'FAMILY'}>{t('FAMILY')}</MenuItem>
                      <MenuItem value={'FRIENDS'}>{t('FRIENDS')}</MenuItem>
                      <MenuItem value={'SOCIAL_ASSISTANCE'}>
                        {t('SOCIAL_ASSISTANCE')}
                      </MenuItem>
                      <MenuItem value={'DOCTOR'}>{t('DOCTOR')}</MenuItem>
                      <MenuItem value={'OTHER'}>{t('OTHER')}</MenuItem>
                    </Select>
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-type')}</p>
                    <Select
                      required
                      label={t('single-type')}
                      onChange={(event) => setResidentType(event.target.value)}
                      defaultValue={data[0].resident_type}
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'REST_HOME'}>{t('rest-home')}</MenuItem>
                      <MenuItem value={'SERVICE_RESIDENCE'}>
                        {t('service-residence')}
                      </MenuItem>
                      <MenuItem value={'SHORT_STAY'}>
                        {t('short-stay')}
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </form>

              <form className="form">
                <h2 className="lead__step__title lead__step__title--margin">
                  {t('single-residence-title')}
                </h2>

                <div className="form__row">
                  <div className="form__field">
                    <p className="form__field__title">{t('budget')}</p>
                    <Select
                      required
                      label={t('budget')}
                      onChange={(event) =>
                        setResidentBudget(event.target.value)
                      }
                      defaultValue={data[0].lead_budget}
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'1500'}>1500&euro;</MenuItem>
                      <MenuItem value={'1500-2000'}>
                        1500&euro;-2000&euro;
                      </MenuItem>
                      <MenuItem value={'2000'}>2000&euro;</MenuItem>
                      <MenuItem value={'2000-2500'}>
                        2000&euro;-2500&euro;
                      </MenuItem>
                      <MenuItem value={'2500-3000'}>
                        2500&euro;-3000&euro;
                      </MenuItem>
                      <MenuItem value={'3000-3500'}>
                        3000&euro;-3500&euro;
                      </MenuItem>
                      <MenuItem value={'+3500'}>+3500&euro;</MenuItem>
                    </Select>
                  </div>
                  <div className="form__field">
                    <p className="form__field__title">{t('origin')}</p>
                    <TextField
                      placeholder={t('origin')}
                      defaultValue={data[0].origin_ref}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-residence-title')}
                    </p>
                    <Select
                      required
                      label="Single Residence"
                      defaultValue={data[0].residences[0].one_key}
                      variant="filled"
                      id="filled-basic"
                    >
                      {data[0].residences.map((residence) => {
                        return (
                          <MenuItem value={residence.one_key}>
                            {residence.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="form__row form__row--width">
                  <div className="form__field form__row--secondary">
                    <p className="form__field__title">{t('questions-notes')}</p>
                    <TextField
                      placeholder={t('questions-notes')}
                      onChange={(event) =>
                        setQuestionRemarks(event.target.value)
                      }
                      defaultValue={data[0].questions_remarks}
                      variant="filled"
                      id="filled-basic"
                      multiline
                      rows={4}
                    />
                  </div>
                </div>

                <div className="form__row form__row--secondary">
                  {reasonField}
                </div>

                <div className="options">
                  <FooterSingle
                    id={id}
                    active={active === '0' ? false : true}
                    handle={locked}
                    type="registration"
                    lastname={data[0].caller_lastname}
                    firstname={data[0].caller_firstname}
                  />
                </div>
              </form>
              <SwitchLanguage />
            </main>
          </div>
        </Suspense>
      </div>
    );
  }

  //------------------------------------------------------------------------------------------------------ QUESTION TYPE HERE
  if (
    data[0] !== undefined &&
    data[0].registration_type === 'GENERAL_QUESTION'
  ) {
    let reasonField = '';

    if (data[0].registration_status_reason !== null) {
      reasonField = (
        <div className="form__field form__row--secondary">
          <p className="form__field__title">{t('closing-reason')}</p>
          <TextField
            defaultValue={t(data[0].registration_status_reason)}
            variant="filled"
            id="filled-basic"
          />
        </div>
      );
    }
    return (
      <div>
        <Suspense fallback={<div>loading...</div>}>
          <div className="wrapper">
            <header>
              <Nav />
            </header>
            <main className="single">
              {openMessage && (
                <NotificationBox
                  num={errorCode}
                  desc={openMessageText}
                  severity={severity}
                />
              )}
              <div className="single__modal">
                <Dialog open={openModal} onClose={handleClose}>
                  <DialogTitle>{t('select-reason')}</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth variant="filled">
                      <Select
                        onChange={(event) => setReason(event.target.value)}
                        variant="filled"
                        id="filled-basic"
                      >
                        <MenuItem value={'ADDRESSED'}>
                          {t('ADDRESSED')}
                        </MenuItem>
                        <MenuItem value={'NOT_ADDRESSED'}>
                          {t('NOT_ADDRESSED')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>{t('cancel-task')}</Button>
                    <Button onClick={() => close(id)} color="primary">
                      {t('add-the-question')}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openModalSend} onClose={handleCloseSend}>
                  <DialogTitle>{t('select-residences')}</DialogTitle>
                  <DialogContent>
                    <FormControl>
                      <Select
                        multiple
                        value={residenceName}
                        onChange={handleChangeSelect}
                        renderValue={(selected) =>
                          selected.map((x) => x.name).join(', ')
                        }
                        variant="filled"
                      >
                        {residencesName.map((variant) => (
                          <MenuItem key={variant.one_key} value={variant}>
                            <Checkbox
                              checked={
                                residenceName.findIndex(
                                  (item) => item.one_key === variant.one_key
                                ) >= 0
                              }
                            />
                            {variant.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseSend}>
                      {t('cancel-task')}
                    </Button>
                    <Button onClick={() => reorient(id)} color="primary">
                      {t('add-the-question')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div className="single__footer__container">
                {data[0].locked !== 0 ? (
                  <>
                    <div className="single__footer__element">
                      <Button
                        className="form__submit form__submit--send"
                        onClick={() => setOpenModalSend(true)}
                      >
                        {t('toolbar-send')}
                      </Button>
                    </div>

                    <div
                      className={
                        data[0].registration_status === 'CLOSED'
                          ? 'single__footer__element single__footer__element--disabled'
                          : 'single__footer__element'
                      }
                    >
                      <Button
                        className="form__submit form__submit--close"
                        onClick={() => setOpenModal(true)}
                      >
                        {t('toolbar-close')}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="margin-left cellClass">
                    <button
                      onClick={() => handleEntry(id)}
                      className="handle incharge incharge--single"
                    >
                      <span className="incharge__text">
                        {t('listing-tr-handle')}
                      </span>
                    </button>
                  </div>
                )}

                <div
                  className={
                    data[0].registration_status === 'OPEN'
                      ? 'single__footer__element single__footer__element--disabled'
                      : 'single__footer__element'
                  }
                >
                  <Button
                    className="form__submit form__submit--open"
                    onClick={() => open(id)}
                  >
                    {t('toolbar-open')}
                  </Button>
                </div>

                {data[0].archived_at === null ? (
                  <div className="single__footer__element">
                    <Button
                      className="form__submit form__submit--archive"
                      onClick={() => archive(id)}
                    >
                      {t('toolbar-archive')}
                    </Button>
                  </div>
                ) : (
                  <div className="single__footer__element">
                    <Button
                      className="form__submit form__submit--archive"
                      onClick={() => unarchive(id)}
                    >
                      {t('toolbar-unarchive')}
                    </Button>
                  </div>
                )}

                <div className="single__footer__element single__footer__element--last">
                  <div className="single__footer__btn single__footer__btn--update">
                    <Button className="form__submit" onClick={Continue}>
                      {t('update')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="single__header">
                <NavLink className="single__header__link" to="/listing" end>
                  {t('previous')}
                </NavLink>
                <h1 className="single__header__title">
                  {data[0].lead_lastname} {data[0].lead_firstname}
                </h1>
                <div className="single__state">
                  <OpenButton open={buttonOpen} />
                </div>
              </div>
              <form className="form">
                <h2 className="lead__step__title lead__step__title--margin">
                  {t('single-resident-info')}
                </h2>

                <div className="form__row">
                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-firstname')}*
                    </p>
                    <TextField
                      required
                      placeholder={t('single-firstname')}
                      onChange={(event) => setResidentName(event.target.value)}
                      defaultValue={data[0].lead_lastname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-lastname')}*
                    </p>
                    <TextField
                      required
                      placeholder={t('single-lastname')}
                      onChange={(event) =>
                        setResidentSurname(event.target.value)
                      }
                      defaultValue={data[0].lead_firstname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-referer')}*</p>
                    <Select
                      required
                      label={t('single-referer')}
                      variant="filled"
                      id="filled-basic"
                      onChange={(event) => setReferer(event.target.value)}
                      defaultValue={data[0].caller_referrer}
                    >
                      <MenuItem disabled value="Referrer">
                        <span className="SelectPlaceholder">
                          {t('single-referer')}
                        </span>
                      </MenuItem>
                      <MenuItem value={'FAMILY'}>{t('FAMILY')}</MenuItem>
                      <MenuItem value={'FRIENDS'}>{t('FRIENDS')}</MenuItem>
                      <MenuItem value={'SOCIAL_ASSISTANCE'}>
                        {t('SOCIAL_ASSISTANCE')}
                      </MenuItem>
                      <MenuItem value={'DOCTOR'}>{t('DOCTOR')}</MenuItem>
                      <MenuItem value={'OTHER'}>{t('OTHER')}</MenuItem>
                    </Select>
                  </div>
                </div>

                <div className="form__row form__row--secondary form__row--width">
                  <div className="form__field">
                    <p className="form__field__title">{t('question')}*</p>
                    <TextField
                      required
                      placeholder={t('question')}
                      onChange={(event) => setQuestion(event.target.value)}
                      defaultValue={data[0].question}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                </div>

                <h2 className="lead__step__title lead__step__title--margin">
                  {t('single-incoming-caller')}
                </h2>
                <div className="form__row">
                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-firstname')}*
                    </p>
                    <TextField
                      required
                      placeholder={t('single-firstname')}
                      onChange={(event) => setName(event.target.value)}
                      defaultValue={data[0].caller_lastname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-lastname')}*
                    </p>
                    <TextField
                      required
                      placeholder={t('single-lastname')}
                      onChange={(event) => setSurname(event.target.value)}
                      defaultValue={data[0].caller_firstname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-phone')}*</p>
                    <TextField
                      required
                      placeholder={t('single-phone')}
                      onChange={(event) => setPhone(event.target.value)}
                      defaultValue={data[0].caller_phone}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                </div>

                <div className="form__row form__row--secondary">
                  <div className="form__field">
                    <p className="form__field__title">{t('single-email')}*</p>
                    <TextField
                      required
                      placeholder={t('single-email')}
                      onChange={(event) => setEmail(event.target.value)}
                      defaultValue={data[0].caller_email}
                      autoComplete="email"
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-language')}*
                    </p>
                    <Select
                      required
                      label={t('single-language')}
                      onChange={(event) => setLanguage(event.target.value)}
                      defaultValue={data[0].caller_language}
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'FR'}>Français</MenuItem>
                      <MenuItem className="menuitem--last" value={'NL'}>
                        Neederlands
                      </MenuItem>
                      {/* 'EN', 'DE', 'IT','TR', 'ES', 'AR' */}
                      <MenuItem value={'EN'}>{t('english')}</MenuItem>
                      <MenuItem value={'DE'}>{t('german')}</MenuItem>
                      <MenuItem value={'IT'}>{t('italian')}</MenuItem>
                      <MenuItem value={'TR'}>{t('turkish')}</MenuItem>
                      <MenuItem value={'ES'}>{t('spanish')}</MenuItem>
                      <MenuItem value={'AR'}>{t('arabic')}</MenuItem>
                    </Select>
                  </div>
                </div>
                <br />
                <div className="form__row form__row--secondary">
                  {reasonField}
                </div>
              </form>
              <div className="options">
                <FooterSingle
                  id={id}
                  active={active === '0' ? false : true}
                  handle={locked}
                  type="question"
                  lastname={data[0].caller_lastname}
                  firstname={data[0].caller_firstname}
                />
              </div>
              <SwitchLanguage />
            </main>
          </div>
        </Suspense>
      </div>
    );
  }

  //------------------------------------------------------------------------------------------------------ COMPLAINT TYPE HERE
  if (data[0] !== undefined && data[0].registration_type === 'COMPLAINT') {
    let reasonField = '';

    if (data[0].registration_status_reason !== null) {
      reasonField = (
        <div className="form__field form__row--secondary">
          <p className="form__field__title">{t('closing-reason')}</p>
          <TextField
            defaultValue={t(data[0].registration_status_reason)}
            variant="filled"
            id="filled-basic"
          />
        </div>
      );
    }
    return (
      <div>
        <Suspense fallback={<div>loading...</div>}>
          <div className="wrapper">
            <header>
              <Nav />
            </header>
            <main className="single">
              {openMessage && (
                <NotificationBox
                  num={errorCode}
                  desc={openMessageText}
                  severity={severity}
                />
              )}
              <div className="single__modal">
                <Dialog open={openModal} onClose={handleClose}>
                  <DialogTitle>{t('select-reason')}</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth variant="filled">
                      <Select
                        onChange={(event) => setReason(event.target.value)}
                        variant="filled"
                        id="filled-basic"
                      >
                        <MenuItem value={'ADDRESSED'}>
                          {t('ADDRESSED')}
                        </MenuItem>
                        <MenuItem value={'NOT_ADDRESSED'}>
                          {t('NOT_ADDRESSED')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>{t('cancel-task')}</Button>
                    <Button onClick={() => close(id)} color="primary">
                      {t('add-the-question')}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openModalSend} onClose={handleCloseSend}>
                  <DialogTitle>{t('select-residences')}</DialogTitle>
                  <DialogContent>
                    <FormControl>
                      <Select
                        multiple
                        value={residenceName}
                        onChange={handleChangeSelect}
                        renderValue={(selected) =>
                          selected.map((x) => x.name).join(', ')
                        }
                        variant="filled"
                      >
                        {residencesName.map((variant) => (
                          <MenuItem key={variant.one_key} value={variant}>
                            <Checkbox
                              checked={
                                residenceName.findIndex(
                                  (item) => item.one_key === variant.one_key
                                ) >= 0
                              }
                            />
                            {variant.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseSend}>
                      {t('cancel-task')}
                    </Button>
                    <Button onClick={() => reorient(id)} color="primary">
                      {t('add-the-question')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div className="single__footer__container">
                {data[0].locked !== 0 ? (
                  <>
                    <div className="single__footer__element">
                      <Button
                        className="form__submit form__submit--send"
                        onClick={() => setOpenModalSend(true)}
                      >
                        {t('toolbar-send')}
                      </Button>
                    </div>

                    <div
                      className={
                        data[0].registration_status === 'CLOSED'
                          ? 'single__footer__element single__footer__element--disabled'
                          : 'single__footer__element'
                      }
                    >
                      <Button
                        className="form__submit form__submit--close"
                        onClick={() => setOpenModal(true)}
                      >
                        {t('toolbar-close')}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="margin-left cellClass">
                    <button
                      onClick={() => handleEntry(id)}
                      className="handle incharge incharge--single"
                    >
                      <span className="incharge__text">
                        {t('listing-tr-handle')}
                      </span>
                    </button>
                  </div>
                )}

                {data[0].archived_at === null ? (
                  <div className="single__footer__element">
                    <Button
                      className="form__submit form__submit--archive"
                      onClick={() => archive(id)}
                    >
                      {t('toolbar-archive')}
                    </Button>
                  </div>
                ) : (
                  <div className="single__footer__element">
                    <Button
                      className="form__submit form__submit--archive"
                      onClick={() => unarchive(id)}
                    >
                      {t('toolbar-unarchive')}
                    </Button>
                  </div>
                )}

                <div className="single__footer__element single__footer__element--last">
                  <div className="single__footer__btn single__footer__btn--update">
                    <Button className="form__submit" onClick={Continue}>
                      {t('update')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="single__header">
                <NavLink className="single__header__link" to="/listing" end>
                  {t('previous')}
                </NavLink>
                <h1 className="single__header__title">
                  {data[0].lead_lastname} {data[0].lead_firstname}
                </h1>
                <div className="single__state">
                  <OpenButton open={buttonOpen} />
                </div>
              </div>

              <form className="form">
                <h2 className="lead__step__title lead__step__title--margin">
                  {t('single-resident-info')}
                </h2>

                <div className="form__row">
                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-firstname')}
                    </p>
                    <TextField
                      required
                      placeholder={t('single-firstname')}
                      onChange={(event) => setResidentName(event.target.value)}
                      defaultValue={data[0].lead_lastname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-lastname')}</p>
                    <TextField
                      required
                      placeholder={t('single-lastname')}
                      onChange={(event) =>
                        setResidentSurname(event.target.value)
                      }
                      defaultValue={data[0].lead_firstname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-referer')}</p>
                    <Select
                      required
                      variant="filled"
                      id="filled-basic"
                      onChange={(event) => setReferer(event.target.value)}
                      defaultValue={data[0].caller_referrer}
                    >
                      <MenuItem disabled value="Referrer">
                        <span className="SelectPlaceholder">
                          {t('single-referer')}
                        </span>
                      </MenuItem>
                      <MenuItem value={'FAMILY'}>{t('FAMILY')}</MenuItem>
                      <MenuItem value={'FRIENDS'}>{t('FRIENDS')}</MenuItem>
                      <MenuItem value={'SOCIAL_ASSISTANCE'}>
                        {t('SOCIAL_ASSISTANCE')}
                      </MenuItem>
                      <MenuItem value={'DOCTOR'}>{t('DOCTOR')}</MenuItem>
                      <MenuItem value={'OTHER'}>{t('OTHER')}</MenuItem>
                    </Select>
                  </div>
                </div>

                <div className="form__row form__row--width">
                  <div className="form__field form__row--secondary">
                    <p className="form__field__title">
                      {t('complaint-title')}*
                    </p>
                    <TextField
                      required
                      placeholder={t('complaint-title')}
                      onChange={(event) => setComplaint(event.target.value)}
                      defaultValue={data[0].complaint}
                      variant="filled"
                      id="filled-basic"
                      multiline
                    />
                  </div>
                </div>

                <h2 className="lead__step__title lead__step__title--margin">
                  {t('single-incoming-caller')}
                </h2>
                <div className="form__row">
                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-firstname')}
                    </p>
                    <TextField
                      required
                      placeholder={t('single-firstname')}
                      onChange={(event) => setName(event.target.value)}
                      defaultValue={data[0].caller_lastname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('single-lastname')}</p>
                    <TextField
                      required
                      placeholder={t('single-lastname')}
                      onChange={(event) => setSurname(event.target.value)}
                      defaultValue={data[0].caller_firstname}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">{t('phone')}*</p>
                    <TextField
                      required
                      placeholder={t('phone')}
                      onChange={(event) => setPhone(event.target.value)}
                      defaultValue={data[0].caller_phone}
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>
                </div>

                <div className="form__row form__row--secondary">
                  <div className="form__field">
                    <p className="form__field__title">{t('single-email')}*</p>
                    <TextField
                      required
                      placeholder={t('single-email')}
                      onChange={(event) => setEmail(event.target.value)}
                      defaultValue={data[0].caller_email}
                      autoComplete="email"
                      variant="filled"
                      id="filled-basic"
                    />
                  </div>

                  <div className="form__field">
                    <p className="form__field__title">
                      {t('single-language')}*
                    </p>
                    <Select
                      required
                      label={t('single-language')}
                      onChange={(event) => setLanguage(event.target.value)}
                      defaultValue={data[0].caller_language}
                      variant="filled"
                      id="filled-basic"
                    >
                      <MenuItem value={'FR'}>Français</MenuItem>
                      <MenuItem className="menuitem--last" value={'NL'}>
                        Neederlands
                      </MenuItem>
                      {/* 'EN', 'DE', 'IT','TR', 'ES', 'AR' */}
                      <MenuItem value={'EN'}>{t('english')}</MenuItem>
                      <MenuItem value={'DE'}>{t('german')}</MenuItem>
                      <MenuItem value={'IT'}>{t('italian')}</MenuItem>
                      <MenuItem value={'TR'}>{t('turkish')}</MenuItem>
                      <MenuItem value={'ES'}>{t('spanish')}</MenuItem>
                      <MenuItem value={'AR'}>{t('arabic')}</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="form__row form__row--secondary">
                  {reasonField}
                </div>
                <br />
              </form>
              <div className="options">
                <FooterSingle
                  id={id}
                  active={active === '0' ? false : true}
                  handle={locked}
                  type="complaint"
                  lastname={data[0].caller_lastname}
                  firstname={data[0].caller_firstname}
                />
              </div>
              <SwitchLanguage />
            </main>
          </div>
        </Suspense>
      </div>
    );
  }
}
