import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { keycloak } from '../context/AuthContextProvider';

export default function Nav() {
  const { t } = useTranslation();

  return (
    <nav className="nav">
      <div className="fixed__wrapper">
        <ul className="nav__list">
          <li className="nav__item nav__item--logo">
            <NavLink to="/">
              <span className="hidden">Emeis logo</span>
            </NavLink>
          </li>

          <div className="nav__container">
            <li className='"nav__item nav__item--overview'>
              <NavLink to="/" end>
                {t('overview')}
              </NavLink>
            </li>
            <li className="nav__item nav__item--listing">
              <NavLink to="/listing" end>
                {t('listing')}
              </NavLink>
            </li>
          </div>

          <li className="add">
            <NavLink to="/entry" end>
              <span className="add__btn">
                <span className="add__text">{t('add')}</span>
              </span>
            </NavLink>
          </li>

          <div className="nav__logout">
            <li className="nav__logout--support">{t('need_support')}</li>
            <li>
              <button className="nav__logout--btn">
                <span
                  className="nav__logout__text"
                  onClick={() => {
                    keycloak.logout();
                    localStorage.removeItem('selectedResidence');
                  }}
                >
                  {t('log_out')}
                </span>
              </button>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
}
