import i18next from 'i18next';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContextProvider';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { keycloak } from '../context/AuthContextProvider';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function SwitchLanguage() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [selectedResidence, setSelectedResidence] = useState('');

  function toggleNavigation() {
    if (document.body.hasAttribute('data-menu')) {
      document.body.removeAttribute('data-menu');
    } else {
      document.body.setAttribute('data-menu', true);
    }
  }

  const decodedToken = JSON.parse(atob(keycloak.token.split('.')[1]));

  const residences = decodedToken['residence'] || '';

  // useEffect(() => {
  //   const initialResidence = localStorage.getItem('selectedResidence') || residences[0];
  //   setSelectedResidence(initialResidence);
  //   if(residences !== '' ) {
  //     localStorage.setItem('selectedResidence', residences[0]);
  //     setSelectedResidence(residences[0])
  //   }
  // }, [location]);  // useEffect will now trigger every time the location changes

  useEffect(() => {
    const initialResidence =
      localStorage.getItem('selectedResidence') || residences[0];
    setSelectedResidence(initialResidence);

    if (!localStorage.getItem('selectedResidence')) {
      localStorage.setItem('selectedResidence', residences[0]);
    }

    if (residences[0] === undefined) {
      localStorage.setItem('selectedResidence', '');
    }
  }, [location, residences]);

  const handleChange = (event) => {
    const newResidence = event.target.value;
    setSelectedResidence(newResidence);
    localStorage.setItem('selectedResidence', newResidence);
  };

  return (
    <div className="nav__secondary">
      <div className="nav__profile" onClick={() => toggleNavigation()}>
        <svg
          className="profile"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0521 4.30432C9.21139 4.30432 6.88696 6.62862 6.88696 9.46942C6.88696 12.3102 9.21126 14.6345 12.0521 14.6345C14.8929 14.6345 17.2172 12.3102 17.2172 9.46942C17.2172 6.62862 14.8929 4.30432 12.0521 4.30432ZM12.0521 12.9128C10.1582 12.9128 8.60866 11.3633 8.60866 9.46942C8.60866 7.57555 10.1582 6.02602 12.0521 6.02602C13.9459 6.02602 15.4955 7.57555 15.4955 9.46942C15.4955 11.3633 13.9459 12.9128 12.0521 12.9128Z"
            fill="#054991"
          />
          <path
            d="M12.0519 0C5.38024 0 0 5.38024 0 12.0519C0 15.3231 1.33433 18.336 3.4434 20.4882C3.95991 21.0047 4.51945 21.4782 5.1651 21.9086C7.102 23.286 9.46935 24.0607 12.0519 24.0607C14.6344 24.0607 17.0019 23.2429 18.9387 21.9086C19.5413 21.4782 20.1009 21.0047 20.6604 20.4882C22.8125 18.293 24.1038 15.3231 24.1038 12.0519C24.1038 5.38024 18.7235 0 12.0519 0ZM12.0519 22.3821C9.46935 22.3821 7.14519 21.4352 5.33727 19.8856C5.81075 18.3361 7.23114 17.217 8.90986 17.217H15.2372C16.9158 17.217 18.3362 18.3361 18.8098 19.8856C16.9589 21.4352 14.6347 22.3821 12.0522 22.3821H12.0519ZM20.1009 18.5084C19.197 16.7006 17.3462 15.4953 15.2372 15.4953H8.90986C6.75774 15.4953 4.90698 16.7435 4.04613 18.5084C2.58269 16.7437 1.72184 14.5055 1.72184 12.0519C1.72184 6.37029 6.37043 1.7217 12.052 1.7217C17.7336 1.7217 22.3822 6.37029 22.3822 12.0519C22.3822 14.5053 21.5214 16.7435 20.101 18.5084H20.1009Z"
            fill="#054991"
          />
        </svg>

        <div className="logincard">
          <div className="logincard__icon">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
              <path
                d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z"
                fill="#F6F7FA"
              />
              <path
                d="M33.5408 32.4177C37.8788 30.9437 41 26.8364 41 22C41 15.9249 36.0751 11 30 11C23.9249 11 19 15.9249 19 22C19 26.8364 22.1212 30.9437 26.4592 32.4177C19.3265 34.1298 14 40.904 14 49H46C46 40.904 40.6735 34.1298 33.5408 32.4177Z"
                fill="#C6D4E5"
              />
            </svg>
          </div>

          <div className="logincard__infos">
            <h4 className="logincard__title">{authContext.username}</h4>
            {authContext.hasRole('user') && (
              <p className="logincard__p">
                <span>User</span>
              </p>
            )}
            {authContext.hasRole('administrator') && (
              <p className="logincard__p">
                <span>Admin</span>
              </p>
            )}
            {authContext.hasRole('director') && (
              <p className="logincard__p">
                <span>Director</span>
              </p>
            )}
            {authContext.hasRole('assistant-director') && (
              <p className="logincard__p">
                <span>Assitant Director</span>
              </p>
            )}
            {authContext.hasRole('sales-coach') && (
              <p className="logincard__p">
                <span>Sales coach</span>
              </p>
            )}
            {authContext.hasRole('hq-agent') && (
              <p className="logincard__p">
                <span>HQ Agent</span>
              </p>
            )}
            {authContext.hasRole('local-agent') && (
              <p className="logincard__p">
                <span>Local agent</span>
              </p>
            )}
            {authContext.hasRole('regional-director') && (
              <p className="logincard__p">
                <span>Regional director</span>
              </p>
            )}
          </div>
        </div>
      </div>

      {residences && (
        <div className="switch">
          <Box sx={{ minWidth: 60 }}>
            <FormControl fullWidth>
              <NativeSelect
                inputProps={{
                  name: 'residences',
                  id: 'uncontrolled-native',
                }}
                onChange={handleChange}
                value={selectedResidence}
                defaultValue={selectedResidence}
              >
                {residences.map((res, index) => (
                  <option key={index} value={res}>
                    {res}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
        </div>
      )}

      <div className="switch">
        <Box sx={{ minWidth: 60 }}>
          <FormControl fullWidth>
            <NativeSelect
              defaultValue={i18next.language}
              inputProps={{
                name: 'language',
                id: 'uncontrolled-native',
              }}
              onChange={(event) => {
                i18next.changeLanguage(event.target.value);
              }}
            >
              <option value={'en'} onClick={() => i18next.changeLanguage('en')}>
                EN
              </option>
              <option value={'fr'} onClick={() => i18next.changeLanguage('fr')}>
                FR
              </option>
              <option value={'nl'} onClick={() => i18next.changeLanguage('nl')}>
                NL
              </option>
            </NativeSelect>
          </FormControl>
        </Box>
      </div>
    </div>
  );
}
